@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Noto+Serif:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Cardo:400,400i,700,700i);
@import "variables";
@import "header.scss";
@import "footer.scss";
body,html{
  margin: 0;
  height: 100%;
  background-color: $body-color;
  font-family: $cardo;
  font-size: 16px;
  line-height: 26px;
}
h1{
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  color: $lu-red;
  position: relative;
  /*height: 40px;*/
  font-size: 24px;
  font-weight: 400;
  font-family: $montserrat;
  margin-bottom: 30px;
  padding-bottom: 10px;
  &:before{
    position: absolute;
    content: "";
    width: 80px;
    height: 1px;
    border-bottom: 3px solid $lu-red;
    bottom: 0;
    left: 50%;
    margin-left: -40px;
  }
  &.text-left{
    text-align: left;

    &:before{
      left: 0;
      margin-left: 0;
    }
  }
}
h2{
  width: auto;
  display: block;
  text-align: left;
  text-transform: uppercase;
  color: $dark-red;
  position: relative;
  height: 40px;
  font-size: 24px;
  font-weight: 400;
  /*border-bottom: 2px solid $dark-gray;*/
  font-family: $montserrat;
}
h3{
  font-family: $montserrat;
  text-transform: uppercase;
}
h4{
  font-family: $montserrat;
  text-transform: uppercase;
  color: $dark-gray;
  font-size: 26px;
}
.banner{
  /* background-color: black; */
  width: 100%;
  height: 380px;
  /* padding: 15px; */
  padding:0;
  overflow: hidden;
}
span{
  &.assinatura{
    font-family: $cardo;
    font-size: 16px;
  }
}
footer{
  background-color: $footer-bg;
  padding: 20px 0;

  .logo{
    img{
    }
  }
  ul{
    padding: 0;
    list-style: none;
    li{
      margin: 10px 0;
      position: relative;
      padding-left: 50px;
      strong{
        text-transform: uppercase;
        color: $dark-red;
      }
      &:before{
        position: absolute;
        color: $dark-gray;
        left: 0;
        font-family: $font-awesome;
        font-size: 20px;
      }
      &.endereco:before{
        content: '\f041';
      }
      &.telefone:before{
        content: '\f095';
      }
      &.whatsapp:before{
        content: '\f232';
      }
      &.email:before{
        content: '\f003';
      }
    }
  }
}
section{
  padding: 20px 0;
  font-family: $cardo;
  &.apresentacao{
    background-color: white;
    p{
      font-size: 18px;
    }
    padding-top: 50px;
    padding-bottom: 50px;
  }
  &.depoimentos{
    h4{
      color: white;
    }
    background-color: $bg-depoimentos;
    color: white;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  &.livro{
    h4{
      color: white;
    }
    background-color: $bg-livro;
    color: white;
  }
  &.cursos{
    background-color: white;
    img{
      margin-bottom: 20px;
    }
  }
  .botao{
    background-color: #fda812;
    padding: 12px;
    color: $lu-red;
    font-family: $montserrat;
    text-transform: uppercase;
  }
  &.namidia{
    h4{
      color: white;
    }
    .underline{
      width: 4em;
      margin-left: auto !important;
    }
    background-color: $bg-namidia;
    color: white;
  }
}
p{
  margin: 10px 0;
}

form{
  text-align: left;
}
label{
  font-family: $cardo;
}
input,select,textarea{
  border-radius: 0 !important;
  line-height: 30px !important;
  border: 1px solid $dark-gray !important;
}
.btn-enviar{
  background-color: $lu-red;
  border-radius: 0;
  color: white;
  font-family: $montserrat;
  text-transform: uppercase;
}
.lu-red{
  color: $lu-red;
}
.lu-sofa{
  width: 80%;
  padding: 10px;
  box-shadow: 10px 10px 5px #888888;
  transform: rotate(5deg);
}
.box-videos{
  text-align: left;
  background-color: $box-videos;
  padding: 20px;
  span{
    &.titulo{
      color: white;
    }
    &.titulo-video {
      color: $yellow;
    }
  }
}
.img-fullwidth{
  width: 100%;
}

.botao{
  background-color: #fda812;
  padding: 12px;
  color: $lu-red;
  font-family: $montserrat;
  text-transform: uppercase;
  font-size: 0.8em;
  text-shadow: none;
}
.botao:hover{
  box-shadow: 1px 1px 5px #fff;
}
#box-fb-lupompoar-container{
  width: 100%;
}
#box-fb-lupompoar{
  background-image: url(../img/bg-fb-preview.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 170px;
  max-width: 100%;
}
.carousel-control-item{
  border: 2px solid #ccc;
  border-radius: 20px;
  font-size: 1.2em !important;
  line-height: 1em !important;
}

@media screen and (min-width: 768px) {
  .carousel-caption {
    left: 15%;
    top: 10%;
    padding-bottom: 50px;
  }
}
.carousel-caption { 
  text-align: left;
}
.carousel-caption .headline{ 
  border: none;
  line-height: 1.5em;
  height: auto;
  font-family: $montserrat;
  font-size: 2em;
}
.carousel-caption .tagline{ 
  padding: 0 0 20px 0;
  font-size: 1.3em;
}
.carousel-caption a{ }

.home-slider-item-1{
  background-color: $bg-slider-item-1;
}
.home-slider-item-2{
  background-color: $bg-slider-item-2;
}
.home-slider-item-3{
  background-color: $bg-slider-item-3;
}
.home-slider-item-1 .carousel-caption .headline{
  color: $color-healine-banner-1;
}
.home-slider-item-2 .carousel-caption .headline{
  color: $color-healine-banner-2;
}
.home-slider-item-3 .carousel-caption .headline{
  color: $color-healine-banner-3;
}
.home-slider-item-2 .botao{
  background-color: #9c2251;
  color: #fff;
}

.carousel-inner > .item img {
  position: relative;
  right: 0;
  float: right;
}
.carousel-inner > .item > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.title{
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  color: $lu-red;
  position: relative;
  height: 40px;
  font-size: 24px;
  font-weight: 400;
  font-family: $montserrat;
  margin-bottom: 30px;
  &:before{
    position: absolute;
    content: "";
    width: 80px;
    height: 1px;
    border-bottom: 3px solid $lu-red;
    bottom: 0;
    left: 50%;
    margin-left: -40px;
  }
  &.text-left{
    text-align: left;

    &:before{
      left: 0;
      margin-left: 0;
    }
  }
}
.subtitle {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  color: $lu-red;
  position: relative;
  height: 40px;
  font-size: 20px;
  font-weight: 400;
  font-family: $montserrat;
  margin-bottom: 30px;
  &:before{
    position: absolute;
    content: "";
    width: 80px;
    height: 1px;
    border-bottom: 3px solid $lu-red;
    bottom: 0;
    left: 50%;
    margin-left: -40px;
  }
  &.text-left{
    text-align: left;

    &:before{
      left: 0;
      margin-left: 0;
    }
  }
}
.font-mserrat{
  font-family: $montserrat;
}
.font-cardo{
  font-family: $cardo;
}
.font-roboto{
  font-family: $roboto-condensed;
}

.text-justify,
.text-just{

}

/** Paginas sobre */
.pg-sobre-destaque-tv{}
.pg-sobre-destaque-tv .container{
  background-color: $bg-sobre-destaque-tv;
  color: black;
}
.destaque-tv-items{
  padding-top: 10px;
  padding-bottom: 10px;
}

/** Paginas cursos */
.curso-subinfos-box{
  border-radius: 0;
}
.curso-infos-box {
  border-radius: 0;
}
.curso-prof-block{

}
.curso-infos-divider{
  margin-top: 5px;
  margin-bottom:  5px;
}
.curso-infos-box .dl-horizontal dt{
  width: auto;
  min-width: 135px;
  text-align: left;
  font-family: $montserrat;
}
.curso-infos-box .dl-horizontal dd{
  margin-left: 150px;

}
.curso-nome-professora{
  margin-top: 0;
  text-transform: uppercase;
  font-weight: bold;
  font-family: $montserrat;
}
.noborder,
.noborder:before{
  border:none !important;
}
.block-grey{
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: $bg-grey;
}
.block-grey-sm{
  min-height: 20px;
  padding: 5px;
  margin-bottom: 10px;
  background-color: $bg-grey;
}

a{
  color: $lu-red;
  text-decoration: none;
}
a:hover{
  text-decoration: none;
}

.text-red{
  color: $lu-red;
}
.bg-red{
  background-color: $lu-red;
}
.border-red{
  border-color: $lu-red;
}
.lup-info{
  background-color: $bg-lup-info;
  color: #fff;
  padding: 15px;
  font-family: $montserrat;
}
.padding-20{
  padding: 20px;
}
.lup-curso-online-info{
  background-color: $bg-lup-curso-online-info;
  color: #fff;
}
.margin-left-50{
  margin-left: 50px;
}

.text-white{
  color: #fff;
}
.curso-pompoarismo{}
.curso-danca-sensual-e-autoestima{}
.curso-preliminar-atraves-do-toque{}
.curso-cha-de-lingerie{}
.curso-terapia-sexual{}

.curso-sidebar{
  padding: 20px;
}
.curso-sidebar.curso-sidebar-pompoarismo{
  background-color: $curso-sidebar-bg-pompoarismo;
  color: #fff;
}
.curso-color-price{
  color: $text-curso-color-price;
}
.curso-big-price{
  font-size: 2em;
  color: $text-curso-color-price;
}
.curso-plano-vez-semana{
  background-color: $lu-red;
  color: #fff;
  font-size: 2em;
  border-radius: 50px;
  text-align: center;
  line-height: 0.5em;
  height: 90px;
  width: 90px;
  padding: 1em 0;
  font-family: $montserrat;
}
.curso-plano-vez-semana strong{
  font-size: 1.3em;
}
.curso-plano-vez-semana small{
  display: inline-block;
  font-size: 0.35em;
}

.curso-sidebar .questions{
  color: $curso-questions-color;
  font-family: $montserrat;
  margin-top: 20px;
  display: inline-block;
  font-weight: normal;
  font-size: 1.2em;
}
.curso-sidebar .questions-sm{
  font-size: 1em; 
}
.curso-sidebar .answers{
  color: #fff;
}
.curso-sidebar .answers{
  margin-top: 0;
}
.curso-sidebar-pompoarismo{
  background-color: $bg-sidebar-pompoarismo;
  color: #fff;
}
.curso-sidebar-danca-sensual-e-autoestima{
  background-color: $bg-sidebar-preliminar;
  color: #fff;
}
.curso-sidebar-preliminar-atraves-do-toque{
  background-color: $bg-sidebar-preliminar;
  color: #fff;
}
.curso-sidebar-cha-de-lingerie{
  background-color: $bg-sidebar-preliminar;
  color: #fff;
}
.curso-sidebar-terapia-sexual{

}
.curso-sidebar-sexo-oral{
  background-color: $bg-sidebar-preliminar;
}

.cha-lingerir-tecnicas-infos {
  background-color: $bg-cha-lingerir-tecnicas-infos;
}
.fsize-1-6{font-size: 1.6em;}
.fsize-1-3{font-size: 1.3em;}
.fsize-1{font-size: 1em;}

.sobre-livro-box-info-00{
  border: 5px solid #fff;
  border-radius: 20px;
  background-color: $bg-box-livro-info-00;
  padding: 15px;
  color: #fff;
}
.pg-sobre-livro-massagem{
  background-color: $bg-sobre-livro-massagem;
  background-image: url('../img/bg-sobre-livro-massagem.png');
  color: #fff;
}
.pg-sobre-livro-10-licoes{

}

.pg-sobre-dvd-pompoarismo{
  background-image: url('../img/bg-sobre-dvd-pompoarismo.png');
}
.img-cover {
  border: 5px solid #fff;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
}
.text-center img{
  margin: 0 auto;
}
.underline{
  width: 4em;
  text-align: left;
  margin-left: 0;
  margin-top: 5px;
  border-top: 4px solid;
  margin-bottom: 10px;
}
.curso-infos-box hr{
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.text-bold{
  font-weight: bold !important;
}



/** review clear */
header{
  background-color: #efefef;
}
header nav{
  padding: 0 0;
  margin-bottom: 0 !important;
}

.navbar-brand > img{
  height: 5em;
  position: relative;
  bottom: 100%;
}

header nav ul li{
  margin-left: 0;
}
header nav ul li a{
  border: none;
  /* max-width: 180px; */
  color: $menu-link-color;
  /* max-width: 135px; */
  font-family: $montserrat;
  font-size: 0.8em;
  padding: 20px 10px !important;
}
.nav > li > a:hover, 
.nav > li > a:focus{
  text-decoration: none;
  background-color: transparent;
  border-bottom: none;
}
.dropdown-menu > li > a{
  max-width: auto;
}
.dropdown-menu > li{
  /* width: 100%;
  display: block; */
}
/** review clear */

.frm-contato input,
.frm-contato select,
.frm-contato button{
  font-family: $montserrat;
}

.navbar-toggle .icon-bar{
  border: 1px solid #333;
}
.dropdown-menu > li {
    /* width: 100% !important; */
}
@media (max-width: 768px) {
  .navbar-brand{
    max-height: 50px;
    height: auto;
    padding: 0;
    padding-left: 15px;
  }
  .navbar-brand > img {
    height: 3em !important;
  }
  .carousel-caption .headline{

    line-height: 1.3em !important;
    font-size: 1.6em  !important;
    // background-color: rgba(0,0,0,0.65);
    // padding: 10px 10px 10px 10px;

  }
  .carousel-inner > .item img{
    opacity: 0.25;
  }

}

@media (max-width: 767px) {
  header nav ul li,
  header nav ul li a{
    width: 100% !important;
  }
}

@media (min-width: 1200px) and (max-width: 1366px) {
  .hidden-dt{
    display: none !important;
  }
  .visible-dt{
    display: block !important;
  }
}
