// Colors
$lu-red: #db2431;
$body-color: white;
$footer-bg: #e6e2d6;
$dark-gray: #444444;
$dark-red: #a51e22;
$bg-depoimentos: #49292d; // #4a2d30;
$bg-livro: #a61212; // #9d1111; 
$bg-namidia: #884746;
$box-videos: #592239;
$yellow: #f4a518;
$bg-sobre-destaque-tv: #faa81a;
$curso-sidebar-bg-pompoarismo: #b32988;
$bg-grey: #f5f5f5;
$bg-lup-info: #92a5c8;
$bg-lup-curso-online-info: #b32988;
$bg-curso-sidebar: #5a2238;
$bg-sidebar-preliminar: #5a2238;
$bg-sidebar-pompoarismo: #b32988;
$curso-questions-color: #febc11; //#faa81a;
$bg-cha-lingerir-tecnicas-infos: #cc3333;
$bg-sobre-livro-massagem: #792146;
$bg-box-livro-info-00: #cc3333;
$menu-link-color: #2e2e2e;
$color-healine-banner-1: #ba5453;
$color-healine-banner-2: #ba5453;
$color-healine-banner-3: #ba5453; //#ded0c3;
$bg-slider-item-1: black;
$bg-slider-item-2: #ffc40f;
$bg-slider-item-3: #ded0c3;
$text-curso-color-price: #3c225f;

// Font
$roboto-condensed: 'Roboto Condensed', sans-serif;
$font-bold: 700;
$font-awesome: 'FontAwesome';
$noto-serif: 'Noto Serif', serif;
$montserrat: 'Montserrat', sans-serif;
$cardo: 'Cardo', serif;