header{
  background-color: white;
  padding: 10px 0;
  nav{
    text-transform: uppercase;
    padding: 40px 0;
    text-align: right;
    ul{
      margin: 0;
      padding: 0;
      font-weight: 700;
      list-style: none;
      li{
        a{
          color: black;
          border-bottom: 1px solid #ccc;
          &:hover{
            text-decoration: none;
            color: $lu-red;
            border-bottom: 1px solid $lu-red;
            transition: 0.5s;
          }
        }
        display: inline-block;
        width: auto;
        float: left;
        margin-left: 20px;
        text-align: center;
        ul{
          display: none;
        }
      }
    }
  }
}